import React, { useEffect, useState, useCallback, useMemo } from 'react';
import sanityClient from '@sanity/client';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import PageBody from 'components/pages/page/PageBody';
import { IPage } from 'components/pages/page/types';

export const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID as string,
  dataset: process.env.GATSBY_SANITY_DATASET as string,
  useCdn: false,
  withCredentials: true,
});

const query = `*[_type == "page" && slug.current == $pageSlug]{
  title,
  "_rawContent": content,
  buttonLink,
  buttonText,
  seoTitle,
  seoDescription,
  h1Text,
  descriptionText,
  adCode,
  showBriefGenerator,
  subtitleText,
  subtitleUrl,
  presetsButtonText,
  presetsButtonUrl,
}`;

const PreviewPage = (): React.ReactNode => {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({} as IPage);
  const location = useLocation();

  const pageSlug = useMemo(() => queryString.parse(location.search)?.slug, [
    location,
  ]);

  const fetchData = useCallback(async () => {
    const data: IPage[] = await client.fetch(query, { pageSlug });

    setPageData(data[data.length - 1]);
    setLoading(false);
  }, [pageSlug]);

  useEffect(() => {
    const subscribeData = client
      .listen(
        query,
        { pageSlug },
        { visibility: 'query', includeResult: false },
      )
      .subscribe(() => {
        setLoading(true);
        fetchData();
      });
    return () => subscribeData.unsubscribe();
  }, [fetchData, pageSlug]);

  useEffect(() => {
    if (pageSlug) fetchData();
  }, [fetchData, pageSlug]);

  if (loading && !Object.keys(pageData).length) {
    return <div>Loading ...</div>;
  }

  return <PageBody data={{ page: pageData }} />;
};

export default PreviewPage;
